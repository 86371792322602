function initMap() {
    const mapEl = document.getElementById('map-canvas');
    const center = {lat:36.3356859, lng:-94.2305118};
  
    const map = new google.maps.Map(mapEl, {
        zoom: 10,
        center: center,
        // mapTypeControlOptions: {
        //   mapTypeIds: []
        // },
        disableDefaultUI: false,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
    });
  
}
  
function loadMapScript() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?&key=AIzaSyA1hxQHXMq1m4-p1PrQUB3RXczhKUk09bs&libraries=places&callback=initMap';
    document.body.appendChild(script);
}

function hideModal(modalEl) {
    let modal = bootstrap.Modal.getInstance(modalEl);
    modal.hide();
    setTimeout(() => {
        document.body.style = '';
        document.body.classList.remove('modal-open');
        document.querySelectorAll('.modal-backdrop').forEach((el) => {
            el.remove();
        });
    }, 500);
}

function bindPlanReferralAdd() {
    document.querySelectorAll('.form-add-plan-referral').forEach((el) => {
        el.addEventListener('submit', (e) => {
            console.log('add plan referral');
            const form = el;
            const formData = new FormData(form);  
            for (const [key, value] of formData.entries()) {
              console.log(key, value);
            }
            e.preventDefault();
            // return false;
            fetch(`/plac/add_referral`, {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    window.location = window.location.href;
                }
            });
        });
    });

}

//notification bubble
setInterval(() => {
    fetch(`/notification/getCount`)
    .then(response => response.json())
    .then(data => {
        if (data.count > 0) {
            document.querySelectorAll('.btn-show-notifications').forEach((el) => {
                el.classList.add('unread');
            });
        }
        else{
            document.querySelectorAll('.btn-show-notifications').forEach((el) => {
                el.classList.remove('unread');
            });
        }
    });
}, 30000);

// Mobile Menu
const mobileNav = document.getElementById('mobile-nav');
const btnMobileNav = document.getElementById('btn-mobile-nav');
btnMobileNav?.addEventListener('click', (e) => {
    mobileNav.classList.toggle('open');
});
const btnCloseMobileNav = document.getElementById('btn-close-mobile-nav');
btnCloseMobileNav?.addEventListener('click', (e) => {
    mobileNav.classList.remove('open');
});

  
const btnShowNotifications = document.querySelectorAll('.btn-show-notifications');
btnShowNotifications?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const notifications = document.querySelector('.notifications-panel');
        notifications.classList.toggle('open');

        let notificationPanel = document.getElementById('unread-notifications');
        notificationPanel.innerHTML = '<div id="loading-spinner" style="padding: 50px; text-align: center; display: none;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>';
        console.log('qweret');


        fetch(`/notification/getUnRead`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                notificationPanel.innerHTML = data.html;

                if (data.count > 0) {
                    document.getElementById('notification-model-bell').classList.add('unread');
                }
                else{
                    document.getElementById('notification-model-bell').classList.remove('unread');
                }

                fetch(`/notification/markRead`)
                .then(response => response.json())
                .then(data => {})
                .catch(error => { console.error('Error:', error) });
            }
            else{
                notificationPanel.innerHTML = 'Error';
            }
        })
        .catch(error => {
            console.error('Error:', error)
            notificationPanel.innerHTML = 'Error';
        });

    });
});


const btnShowUnreadNotifications = document.querySelector('.btn-show-unread-notifications');
const btnShowReadNotifications = document.querySelector('.btn-show-read-notifications');
btnShowReadNotifications?.addEventListener('click', (e) => {
    const readNotifications = document.querySelector('.notification-list-read');
    const unreadNotifications = document.querySelector('.notification-list-unread');
    btnShowReadNotifications.classList.add('active');
    btnShowUnreadNotifications.classList.remove('active');
    unreadNotifications.style.display = 'none';
    readNotifications.style.display = 'block';

    let notificationPanel = document.getElementById('read-notifications');
    notificationPanel.innerHTML = '<div id="loading-spinner" style="padding: 50px; text-align: center; display: none;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>';

    fetch(`/notification/getRead`)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            notificationPanel.innerHTML = data.html;
        }
        else{
            notificationPanel.innerHTML = 'Error';
        }
    })
    .catch(error => {
        console.error('Error:', error)
        notificationPanel.innerHTML = 'Error';
    });

});
btnShowUnreadNotifications?.addEventListener('click', (e) => {
    const readNotifications = document.querySelector('.notification-list-read');
    const unreadNotifications = document.querySelector('.notification-list-unread');
    btnShowReadNotifications.classList.remove('active');
    btnShowUnreadNotifications.classList.add('active');
    unreadNotifications.style.display = 'block';
    readNotifications.style.display = 'none';
});
  
const btnCloseNotifications = document.querySelector('.btn-close-notifications');
btnCloseNotifications?.addEventListener('click', (e) => {
    const notifications = document.querySelector('.notifications-panel');
    notifications.classList.remove('open');
});
  
// Modal in URL - If a modal is in the URL, show it
const urlParams = new URLSearchParams(window.location.search);
const modal = urlParams.get('modal');
if (modal) {
    const modalEl = document.getElementById(modal);
    const modalInstance = new bootstrap.Modal(modalEl);
    modalInstance.show();
}
  
const toastMessage = urlParams.get('tmsg');
if (toastMessage) {
    const toast = document.getElementById(toastMessage);
    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
    toastBootstrap.show();

}
  
if(document.getElementById('notify-success')){
    const toastNotify = bootstrap.Toast.getOrCreateInstance(document.getElementById('notify-success'));
    toastNotify.show();
}


if(document.getElementById('schedule-now-modal')){

    document.getElementById('schedule-now-modal').addEventListener('hidden.bs.modal', function () {
        clearEventForm();
    });

    document.getElementById('schedule-now').addEventListener('click', function() {
        document.getElementById('EventFormHeading').value = 'Schedule New Event';
        clearEventForm();

        new bootstrap.Modal(document.getElementById('schedule-now-modal')).show();
    });

    document.getElementById('EventForm').addEventListener('submit', function(e){
        e.preventDefault();
        const form = new FormData(this);
        
        fetch('/calendar/EventForm', {
            method: 'POST',
            body: form
        })
        .then(response => response.json())
        .then(data => {
            if(data.error){
                document.getElementById('EventForm_error').innerHTML = data.error;
                document.getElementById('EventForm_error').style.display = 'block';
            }else{
                document.getElementById('EventForm_action_cancel').click();
                alert('Event scheduled successfully!');
            }
        })
        .catch(error => console.error('Error submitting event form:', error));
    });

    function clearEventForm(){
        document.getElementById('EventForm_EventTitle').value = '';
        document.getElementById('EventForm_EventDate').value = '';
        document.getElementById('EventForm_EventTime').value = '';
        document.getElementById('EventForm_EventLocation').value = '';
        document.getElementById('EventForm_Message').value = '';
        document.getElementById('EventForm_EventID').value = '0';
        document.getElementById('EventForm_EventType').value = '1';
    }
}


// Ellipsis Expanded Menu Component
// When a user clicks on the button, open the next list menu
document.addEventListener('click', (e) => {
    if (e.target.classList.contains('btn-expand-menu')) {
        const button = e.target;
        const menu = button.closest('.list-menu-wrap').querySelector('.list-menu');
        button.classList.toggle('active');
        button.parentNode.classList.toggle('open');
        menu.classList.toggle('show');
        // If the menu is opened create a shade element and append to the body
        if (menu.classList.contains('show')) {
            const shade = document.createElement('div');
            shade.classList.add('list-menu-shade');
            document.body.appendChild(shade);
        } else {
            const shade = document.querySelector('.list-menu-shade');
            shade.remove();
        }        
        // prevent the click event from bubbling up to the parent
        e.stopPropagation();
        return false;

    }
});

document.addEventListener('click', (e) => {
    if (e.target.classList.contains('btn-list-menu')) {
        const button = e.target;
        const menu = button.closest('.list-menu-wrap').querySelector('.list-menu');
        button.classList.toggle('active');
        button.parentNode.classList.toggle('open');
        menu.classList.toggle('show');
        // If the menu is opened create a shade element and append to the body
        if (menu.classList.contains('show')) {
            const shade = document.createElement('div');
            shade.classList.add('list-menu-shade');
            document.body.appendChild(shade);
        } else {
            const shade = document.querySelector('.list-menu-shade');
            shade.remove();
        }
        // prevent the click event from bubbling up to the parent
        e.stopPropagation();
        return false;

    }
});
document.addEventListener('click', (e) => {
    if (e.target.classList.contains('list-menu-shade')) {
        const shade = e.target;
        const button = document.querySelector('.btn-list-menu.active');
        const buttonExpand = document.querySelector('.btn-expand-menu.active');
        const menu = document.querySelector('.list-menu.show');
        const menuWrap = document.querySelector('.list-menu-wrap.open');
        menuWrap?.classList.remove('open');
        button?.classList.remove('active');
        buttonExpand?.classList.remove('active');
        menu.classList.remove('show');
        shade.remove();
        e.stopPropagation();
        return false;
    }
});

// When a user clicks an item in the expanded menu, close the menu
document.addEventListener('click', (e) => {
    if (e.target.closest('.list-menu')) {
        const menu = e.target.closest('.list-menu');
        const button = menu.previousElementSibling;
        button.classList.remove('active');
        menu.classList.remove('show');
        const shade = document.querySelector('.list-menu-shade');
        shade.remove();
        e.stopPropagation();
        return false;
    }
});

// const menuItems = document.querySelectorAll('.list-menu a');
// menuItems.forEach((el) => {
//     el.addEventListener('click', (e) => {
//         const menu = e.currentTarget.closest('.list-menu');
//         const button = menu.previousElementSibling;
//         button.classList.remove('active');
//         menu.classList.remove('show');
//         // e.stopPropagation();
//     });
// });

// const listMenuWrap = document.querySelectorAll('.list-menu-wrap');
// listMenuWrap.forEach((el) => {
//     el.addEventListener('click', (e) => {
//         // event event target is not an a tag
//         if (e.target.tagName !== 'A') {
//             const button = e.currentTarget.querySelector('.btn-expand-menu');
//             const menu = e.currentTarget.querySelector('.list-menu');
//             button.classList.remove('active');
//             menu.classList.remove('show');
//         }
//     });
// });
  
  
// Modals ---------------------------------------------------
if (document.querySelector('.profile-modal')) {
    const profileModal = new bootstrap.Modal('.profile-modal');
    const btnProfile = document.querySelectorAll('.btn-view-profile');
    btnProfile.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            profileModal.show();
        });
    });
}
  
if (document.querySelector('.archive-modal')) {
    const archiveModal = new bootstrap.Modal('.archive-modal');
    const btnArchive = document.querySelectorAll('.btn-archive-client');
    btnArchive.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            archiveModal.show();
        });
    });
}
  
// if (document.querySelector('.report-duplicate-modal')) {
//     const reportDuplicateModal = new bootstrap.Modal('.report-duplicate-modal');
//     const btnReportDuplicate = document.querySelectorAll('.btn-report-duplicate');
//     btnReportDuplicate.forEach((el) => {
//         el.addEventListener('click', (e) => {
//             e.preventDefault();
//             reportDuplicateModal.show();
//         });
//     });
// }

  
if (document.getElementById('map-canvas')) {
    loadMapScript();
}
  
resourceCategorySelect = document.getElementById('resource-category-select');
resourceCategorySelect?.addEventListener('change', (e) => {
    const selectedCategory = e.target.value;
    window.location.href = `${selectedCategory}`;
});
  
  
// Share Buttons
const share_copyLink = document.querySelectorAll('.btn-copy-link');
share_copyLink?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const link = window.location.href;
        navigator.clipboard.writeText(link);
    });
});
  
const share_facebook = document.querySelectorAll('.btn-share-facebook');
share_facebook?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const link = window.location.href;
        const url = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
        window.open(url, '_blank');
    });
});
  
const share_twitter = document.querySelectorAll('.btn-share-twitter');
share_twitter?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const link = window.location.href;
        const url = `https://twitter.com/intent/tweet?url=${link}`;
        window.open(url, '_blank');
    });
});
  
const share_linkedin = document.querySelectorAll('.btn-share-linkedin');
share_linkedin?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const link = window.location.href;
        const url = `https://www.linkedin.com/shareArticle?url=${link}`;
        window.open(url, '_blank');
    });
});
  
  
// Forms
const uploadField = document.querySelectorAll('[type="file"]');
  
uploadField?.forEach((el) => {
    el.onchange = function() {
        let limit = el.dataset.limit;
        let limitLabel = el.dataset.limitlabel;
        console.log(this.files[0].size);
        console.log(limit);
        if(this.files[0].size > parseInt(limit)) {
            alert("File size must be less than " + limitLabel);
            this.value = "";
        }
    };
});
  
// Client Portal Account
var needsList = document.getElementById('needs-list-sortable');
if (needsList) {
    var sortable = Sortable.create(needsList, {
        onUpdate: function (evt) {
            var order = [];
            var needs = needsList.querySelectorAll('.needs-list-item');
            needs.forEach((el) => {
                order.push(el.dataset.item);
            });
            console.log(JSON.stringify({needs: order}));
            fetch('/cac/clientSortNeeds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({'needs': order}),
            })
        }
    });
}
  
// Client Notifications
let notificationCheckbox = document.querySelectorAll('.client-notification-checkbox');
notificationCheckbox?.forEach((el) => {
    el.addEventListener('change', (e) => {
        // remove the checked attribute for the other checkboxes
        notificationCheckbox.forEach((checkbox) => {
            if (checkbox !== e.target) {
                checkbox.checked = false;
            }
        });
        fetch(`/cac/update_notifications/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'notificationType': e.target.value}),
        })
    });
});
  
// Files
let btnDeleteClientFile = document.querySelectorAll('.btn-delete-client-file');
btnDeleteClientFile?.forEach((el) => {
    el.addEventListener('click', (e) => {
        let fileId = e.target.dataset.fileid;
        fetch(`/cac/delete_client_file/${fileId}`, {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                document.getElementById(`client-file-${fileId}`).remove();
                let modal = bootstrap.Modal.getInstance(document.getElementById(`confirm-delete-modal-${fileId}`));
                modal.hide();
            }
        });
    });
});
  
// Client Dashboard
let btnConfirmEmail = document.getElementById('btn-confirm-email');
btnConfirmEmail?.addEventListener('click', (e) => {
    fetch(`/cac/confirm_email`, {
        method: 'POST',
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            document.getElementById('alert-confirm-email').remove();
        }
    });
});
let btnCloseTile = document.querySelectorAll('.btn-close-tile');
btnCloseTile?.forEach((el) => {
    el.addEventListener('click', (e) => {
        let tile = e.target.closest('.tile');
        tile.remove();
    });
});
  


let btnConfirmSendRequest = document.getElementById('btn-confirm-send-request');
btnConfirmSendRequest?.addEventListener('click', (e) => {
    fetch(`/cac/confirm_send_request`, {
        method: 'POST',
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            document.getElementById('alert-confirm-send-request').remove();
        }
    });
});

// Client Plans
let searchClientPlans = document.getElementById('search-client-plans');
searchClientPlans?.addEventListener('keyup', (e) => {
    const searchValue = e.target.value;
    if (searchValue.length >= 3) {
      fetch(`/cac/plans?search=${searchValue}`)
          .then(response => response.json())
          .then(data => {
              let plans = document.getElementById('client-plans');
              plans.innerHTML = data.html;
          });
      }
});

let filterClientPlans = document.getElementById('filter-client-plans');
filterClientPlans?.addEventListener('change', (e) => {
    const filterValue = e.target.value;
    fetch(`/cac/plans?status=${filterValue}`)
        .then(response => response.json())
        .then(data => {
          let plans = document.getElementById('client-plans');
          plans.innerHTML = data.html;
      });
});
  
//   const clientPlansContainer = document.querySelector('.ClientPlansPage');
//   clientPlansContainer?.addEventListener('click', function (e) {
//       if (e.target.classList.contains('btn-update-checklist-item')) {
//           let checklistItemId = e.target.dataset.item;
//           let status = e.target.dataset.status;
//           let checklistId = e.target.dataset.checklist;
//           fetch(`/cac/update_plan_item/${checklistItemId}?status=${status}`, {
//               method: 'POST',
//           })
//           .then(response => response.json())
//           .then(data => {
//               if (data.success) {
//                   let checklist = document.getElementById(`plan-checklist-${checklistId}`);
//                   let header = document.getElementById('plan-detail-header');
//                   let newHeader = document.createElement('div');
//                   newHeader.innerHTML = data.header;
//                   header.replaceWith(newHeader);
//                   checklist.parentElement.innerHTML = data.html;
//               }
//           });
//           e.stopPropagation();
//           e.preventDefault();
//           return false;
//       }
//   });

let btnUpdateChecklistItem = document.querySelectorAll('.btn-update-checklist-item');
btnUpdateChecklistItem?.forEach((el) => {
    el.addEventListener('click', (e) => {
        let target = e.target;
        let progressBar = document.getElementById('plan-progress');
        let progressPercentageLabel = document.getElementById('plan-progress-percentage');
        let checklist = target.closest('.step-accordion');
        let listStepsCompleted = checklist.querySelector('.list-steps-completed');
        let checklistItemId = target.dataset.item;
        let status = target.dataset.status;
          fetch(`/cac/update_plan_item/${checklistItemId}?status=${status}`, {
              method: 'POST',
          })
          .then(response => response.json())
          .then(data => {
              if (data.success) {
                let completedPercentage = data.completedPercentage;
                progressBar.style.width = `${completedPercentage}%`;
                progressPercentageLabel.innerHTML = `${completedPercentage}%`;
                target.classList.toggle('complete');
                listStepsCompleted.innerHTML = data.listCompletedItems;
                if (status == 'Complete') {
                    target.dataset.status = 'Incomplete';
                } else {
                    target.dataset.status = 'Complete';
                }
              }
          });

        e.stopPropagation();
        e.preventDefault();
        return false;
    });
});


// Connector Tool
const myClientSearchField = document.querySelector('.my-client-search-field');
myClientSearchField?.addEventListener('keyup', (e) => {
    const searchValue = e.target.value;
    if (searchValue.length >= 3) {
      fetch(`/pac/myclients?search=${searchValue}`)
          .then(response => response.json())
          .then(data => {
              let clients = document.getElementById('client-list');
              clients.innerHTML = data.clients;
          });
    } else if (e.keyCode === 8 && searchValue.length === 0) {
        fetch(`/pac/myclients`)
            .then(response => response.json())
            .then(data => {
                let clients = document.getElementById('client-list');
                clients.innerHTML = data.clients;
            });
    }
});
const orgClientSearchField = document.querySelector('.org-client-search-field');
orgClientSearchField?.addEventListener('keyup', (e) => {
    const searchValue = e.target.value;
    if (searchValue.length >= 3) {
      fetch(`/pac/myorgclients?search=${searchValue}`)
          .then(response => response.json())
          .then(data => {
              let clients = document.getElementById('client-list');
              clients.innerHTML = data.clients;
          });
    } else if (e.keyCode === 8 && searchValue.length === 0) {
        fetch(`/pac/myorgclients`)
            .then(response => response.json())
            .then(data => {
                let clients = document.getElementById('client-list');
                clients.innerHTML = data.clients;
            });
    }
});

const clientSearchField = document.querySelector('.client-search-field');
clientSearchField?.addEventListener('keyup', (e) => {
    const searchValue = e.target.value;
    if (searchValue.length >= 3) {
      fetch(`/pac/clients?search=${searchValue}`)
          .then(response => response.json())
          .then(data => {
            let clients = document.getElementById('client-list');
            let pagination = document.getElementById('pagination');
            clients.innerHTML = data.clients;
            pagination.innerHTML = data.pagination;
          });
      }
      if (e.keyCode === 8 && searchValue.length === 0) {
        fetch(`/pac/clients`)
            .then(response => response.json())
            .then(data => {
                let clients = document.getElementById('client-list');
                clients.innerHTML = data.clients;
            });
    }
});


const clientSort = document.querySelectorAll('.client-sort');
clientSort?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const list = e.target.closest('.data-table').dataset.list;
        const sortValue = e.target.dataset.sortby;
        const sort = e.target.dataset.sort;
        switch (sort) {
            case 'ASC':
                e.target.dataset.sort = 'DESC';
                break;
            case 'DESC':
                e.target.dataset.sort = 'ASC';
                break;
            default:
                e.target.dataset.sort = 'ASC';
                break;
        }
        clientSort.forEach((sortButton) => {
            if (sortButton !== e.target) {
                sortButton.dataset.sort = '';
            }
        });

        fetch(`/pac/${list}?sortby=${sortValue}&sort=${e.target.dataset.sort}`)
            .then(response => response.json())
            .then(data => {
                let clients = document.getElementById('client-list');
                let pagination = document.getElementById('pagination');
                clients.innerHTML = data.clients;
                pagination.innerHTML = data.pagination;
        });
    });
});

const plansTabNav = document.getElementById('plans-tab-nav');
plansTabNav?.addEventListener('click', (e) => {
    if (e.target.classList.contains('tab-link')) {
        const tab = e.target.dataset.status;
        plansTabNav.querySelectorAll('.tab-link').forEach((el) => {
            el.classList.remove('active');
        });
        e.target.classList.add('active');
        fetch(`/plac/plan_search?status=${tab}`)
            .then(response => response.json())
            .then(data => {
                let plans = document.getElementById('plans-list');
                plans.insertAdjacentHTML('beforebegin', data.html);
                plans.remove();
            }
        );
    }
});

const btnClaim = document.querySelectorAll('.btn-claim-request');
btnClaim.forEach((el) => {
    el.addEventListener('click', (e) => {
        const spinner = document.createElement('i');
        spinner.classList.add('fa-solid', 'fa-arrows-spin','fa-spin');
        spinner.setAttribute('role', 'status');
        spinner.setAttribute('aria-hidden', 'true');
        e.target.replaceWith(spinner);

        const requestId = e.target.dataset.request;
        fetch(`/pac/claim_request/${requestId}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                window.location.href = data.redirect;
                const modalEl = document.getElementById(`claim-client-modal-${requestId}`);
                const modalInstance = bootstrap.Modal.getInstance(modalEl);
                const clientRow = document.getElementById(`client-row-${requestId}`);
                // console.log(data.html);
                // clientRow.insertAdjacentHTML('beforebegin', data.html);
                clientRow.remove();


                // modalInstance.hide();
                // document.querySelector('.modal-backdrop').remove();
                // setTimeout(() => {document.body.style = '';}, 500);
            } else {
                alert(`Error: ${data.error}`);
            }
        });
    });
});


const btnDuplicateRequest = document.querySelectorAll('.btn-duplicate-request');
btnDuplicateRequest.forEach((el) => {
    el.addEventListener('click', (e) => {
        const requestId = e.target.dataset.request;
        fetch(`/pac/duplicate_request/${requestId}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                window.location.href = data.redirect;
                // const modalEl = document.getElementById(`claim-client-modal-${requestId}`);
                // const modalInstance = bootstrap.Modal.getInstance(modalEl);
                // const clientRow = document.getElementById(`client-row-${requestId}`);
                // clientRow.insertAdjacentHTML('beforebegin', data.html);
                // clientRow.remove();
                // modalInstance.hide();
                // document.querySelector('.modal-backdrop').remove();
                // setTimeout(() => {document.body.style = '';}, 500);
            } else {
                alert(`Error: ${data.error}`);
            }
        });
    });
});


const btnDenyRequest = document.querySelectorAll('.btn-deny-request');
btnDenyRequest.forEach((el) => {
    el.addEventListener('click', (e) => {
        const requestId = e.target.dataset.request;
        fetch(`/pac/deny_request/${requestId}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                window.location.href = data.redirect;
                const modalEl = document.getElementById(`claim-client-modal-${requestId}`);
                const modalInstance = bootstrap.Modal.getInstance(modalEl);
                const clientRow = document.getElementById(`client-row-${requestId}`);
                clientRow.insertAdjacentHTML('beforebegin', data.html);
                clientRow.remove();
                modalInstance.hide();
                document.querySelector('.modal-backdrop').remove();
                setTimeout(() => {document.body.style = '';}, 500);
            } else {
                alert(`Error: ${data.error}`);
            }
        });
    });
});

const btnUnclaim = document.querySelectorAll('.btn-unclaim-request');
btnUnclaim.forEach((el) => {
    el.addEventListener('click', (e) => {
        const requestId = e.target.dataset.request;
        fetch(`/pac/unclaim_request/${requestId}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                const modalEl = document.getElementById(`unclaim-client-modal-${requestId}`);
                const modalInstance = bootstrap.Modal.getInstance(modalEl);
                modalInstance.hide();
                document.querySelector('.modal-backdrop').remove();
                setTimeout(() => {document.body.style = '';}, 500);
            }
        });
    });
});


let practitionerNotificationCheckbox = document.querySelectorAll('.practitioner-notification-checkbox');
practitionerNotificationCheckbox?.forEach((el) => {
    el.addEventListener('change', (e) => {
        // remove the checked attribute for the other checkboxes
        el.closest('.notification-option-row').querySelectorAll('.practitioner-notification-checkbox').forEach((checkbox) => {
            if (checkbox !== e.target) {
                checkbox.checked = false;
            }
        });
        fetch(`/pac/update_notifications/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'notificationType': e.target.dataset.type, 'notificationValue': e.target.value}),
        })
    });
});


// Plan creation & editing
const planInput = document.querySelectorAll('.plan-input');
planInput?.forEach(el => {
    el.addEventListener('keyup', (e) => {
        const name = e.target.getAttribute('name');
        const value = e.target.value;
        const planID = e.target.dataset.plan;
        if (value.length >= 3) {
            fetch(`/plac/update/${planID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({[name]: value}),
            })
        }
    });
});

const btnEditPlanDescription = document.getElementById('btn-edit-plan-description');
btnEditPlanDescription?.addEventListener('click', (e) => {
    const planDescription = document.getElementById('plan-description');
    const planDescriptionEdit = document.getElementById('plan-description-edit');
    const planDescriptionInput = document.getElementById('plan-description-input');

    e.target.style.display = 'none';
    planDescription.style.display = 'none';
    planDescriptionEdit.style.display = 'block';
    planDescriptionInput.focus();
});
const btnSaveDescription = document.getElementById('btn-save-description');
btnSaveDescription?.addEventListener('click', (e) => {
    const planDescription = document.getElementById('plan-description');
    const planDescriptionEdit = document.getElementById('plan-description-edit');
    const planDescriptionInput = document.getElementById('plan-description-input');

    planDescription.innerHTML = planDescriptionInput.value;
    planDescription.style.display = 'block';
    planDescriptionEdit.style.display = 'none';
    btnEditPlanDescription.style.display = 'block';
});

const planClientSearch = document.getElementById('plan-client-search');
planClientSearch?.addEventListener('keyup', (e) => {
    const searchValue = e.target.value;
    if (searchValue.length >= 3) {
        fetch(`/plac/client_search?search=${searchValue}`)
            .then(response => response.json())
            .then(data => {
                let clients = document.getElementById('client-search-list');
                clients.innerHTML = data.html;
            });
    }
});

// Search referral organizations for plan addition
referralOrganizationSearch = document.getElementById('search-referral-organizations');
referralOrganizationSearch?.addEventListener('keyup', (e) => {
    const searchValue = e.target.value;
    const planID = e.target.dataset.plan;
    if (searchValue.length >= 3) {
        fetch(`/plac/org_search?search=${searchValue}&plan=${planID}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let organizations = document.getElementById('referral-organizations-list');
                    organizations.innerHTML = data.html;
                    bindPlanReferralAdd();
                }
            });
    }
});

const filterReferralsService = document.getElementById('filter-referrals-service');
filterReferralsService?.addEventListener('change', (e) => {
    const organizations = document.getElementById('referral-organizations-list');
    const serviceID = e.target.value;
    const city = document.getElementById('filter-referrals-city').value;
    const urlParams = city ? `?city=${city}&service=${serviceID}` : `?service=${serviceID}`;
    organizations.innerHTML = '<div class="d-flex justify-content-center align-items-center text-large h-100"><i class="fa-solid fa-spinner fa-spin"></i></div>';
    fetch(`/plac/org_search${urlParams}`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                organizations.innerHTML = data.html;
            }
        });
});
const filterReferralsCity = document.getElementById('filter-referrals-city');
filterReferralsCity?.addEventListener('change', (e) => {
    const organizations = document.getElementById('referral-organizations-list');
    const city = e.target.value;
    const serviceID = document.getElementById('filter-referrals-service').value;
    const urlParams = serviceID ? `?city=${city}&service=${serviceID}` : `?city=${city}`;
    organizations.innerHTML = '<div class="d-flex justify-content-center align-items-center text-large h-100"><i class="fa-solid fa-spinner fa-spin"></i></div>';
    fetch(`/plac/org_search${urlParams}`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                organizations.innerHTML = data.html;
            }
        });
});
const btnClearOrgSearchFilters = document.getElementById('btn-clear-org-search-filters');
btnClearOrgSearchFilters?.addEventListener('click', (e) => {
    const organizations = document.getElementById('referral-organizations-list');
    organizations.innerHTML = '<div class="d-flex justify-content-center align-items-center text-large h-100"><i class="fa-solid fa-spinner fa-spin"></i></div>';
    fetch(`/plac/org_search`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                organizations.innerHTML = data.html;
            }
        });
});

const btnToggleReferralView = document.querySelectorAll('.btn-toggle-referral-view');
btnToggleReferralView?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const view = e.target.dataset.view;
        const findReferralsModule = document.getElementById('find-referrals-module');
        if (view == 'list') {
            findReferralsModule.classList.add('list-view');
        } else {
            findReferralsModule.classList.remove('list-view');
        }
        if (e.target.classList.contains('active')) {
            return;
        } else {
            btnToggleReferralView.forEach((btn) => {
                btn.classList.remove('active');
            });
            e.target.classList.add('active');
        }
    });
});


const btnSaveChecklist = document.getElementById('btn-save-checklist');
btnSaveChecklist?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    const titleInput = document.getElementById('new-checklist-title');
    const title = titleInput.value;
    if (!title.length) {
        return;
    }
    fetch(`/plac/create_checklist/${planID}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'Title': title}),
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            let checklists = document.getElementById('checklists');
            let modal = e.target.closest('.hark-modal');
            checklists.innerHTML += data.html;
            titleInput.value = '';
            modal.style.display = 'none';    
        }
    });
});


const newChecklistInput = document.getElementById('new-checklist-title');
newChecklistInput?.addEventListener('keyup', (e) => {
    if (e.target.value.length >= 3 && e.key === 'Enter') {
        const planID = e.target.dataset.plan;
        const titleInput = document.getElementById('new-checklist-title');
        const title = titleInput.value;
        if (!title.length) {
            return;
        }
        fetch(`/plac/create_checklist/${planID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'Title': title}),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                let checklists = document.getElementById('checklists');
                let modal = e.target.closest('.hark-modal');
                checklists.innerHTML += data.html;
                titleInput.value = '';
                modal.style.display = 'none';    
            }
        });
    }
});

const btnOpenChecklistModal = document.getElementById('btn-open-checklist-modal');
btnOpenChecklistModal?.addEventListener('click', (e) => {
    const checklistModal = document.getElementById('add-checklist-modal');
    checklistModal.style.display = 'block';
    checklistModal.querySelector('input').focus();
});

const btnOpenClientModal = document.querySelectorAll('.btn-open-client-modal');
btnOpenClientModal?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const clientModal = document.getElementById('add-client-modal');
        clientModal.style.display = 'block';
        clientModal.querySelector('input').focus();
    });
});

const clientSearchList = document.getElementById('client-search-list');
clientSearchList?.addEventListener('click', (e) => {
    if (e.target.classList.contains('client-search-result')) {
        const clientID = e.target.dataset.client;
        const planID = document.getElementById('add-client-modal').dataset.plan;
        fetch(`/plac/add_client/${planID}/${clientID}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                window.location.href = data.redirect;
                // let clients = document.getElementById('clients');
                // clients.innerHTML += data.html;
                // let modal = e.target.closest('.hark-modal');
                // modal.style.display = 'none';
            }
        });
    }
});

const btnCloseModal = document.querySelectorAll('.btn-close-modal');
btnCloseModal?.forEach((el) => {
    el.addEventListener('click', (e) => {
        let modal = e.target.closest('.hark-modal');
        let searchInput = document.getElementById('plan-client-search');
        let searchList = document.getElementById('client-search-list');
        searchInput.value = '';
        searchList.innerHTML = '';
        modal.style.display = 'none';
    });
});

const btnPublishPlan = document.getElementById('btn-publish-plan');
btnPublishPlan?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    fetch(`/plac/publish/${planID}`, {
        method: 'POST',
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            window.location.href = data.redirect;
        }
    });
});

const btnUpdatePlanClient = document.getElementById('btn-update-plan-client');
btnUpdatePlanClient?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    const clientID = document.getElementById('plan-client-select').value;
    if (!clientID) {
        return;
    }
    fetch(`/plac/update/${planID}`, {
        method: 'POST',
        body: JSON.stringify({['ClientID']: clientID}),
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            // Refresh the page
            window.location = window.location.href;
            // window.location.href = data.redirect;
        }
    });
});
const btnUpdatePlanConnector = document.getElementById('btn-update-plan-connector');
btnUpdatePlanConnector?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    const connectorID = document.getElementById('plan-client-select').value;
    if (!connectorID) {
        return;
    }
    fetch(`/plac/update/${planID}`, {
        method: 'POST',
        body: JSON.stringify({['ConnectorID']: connectorID}),
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            // Refresh the page
            window.location = window.location.href;
            // window.location.href = data.redirect;
        }
    });
});

const btnAddPlanNote = document.getElementById('btn-add-plan-note');
btnAddPlanNote?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    const form = document.getElementById('form-add-plan-note');
    const note = form.querySelector('textarea').value;
    if (!note.length) {
        return;
    }

    const ptype = document.getElementById('plan-note-type').value;
    const fdate = document.getElementById('plan-followup-date').value;
    const ftime = document.getElementById('plan-followup-time').value;
    const showfollowup = document.getElementById('show-followup-date').checked;


    if (document.getElementById('show-followup-date').checked) {
        if (!fdate.length) {
            alert('Please enter a follow up date');
            return;
        }
    }

    fetch(`/plac/add_note/${planID}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'Note': note, 'Type': ptype, 'FollowUpDate': fdate, 'FollowUpTime': ftime, 'ShowFollowUp': showfollowup}),
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            // let notes = document.getElementById('plan-notes');
            // notes.innerHTML += data.html;
            form.querySelector('textarea').value = '';
            // Close modal
            const modal = document.getElementById('add-plan-note-modal');
            hideModal(modal);

            const planActivityFeed = document.getElementById('plan-activity-feed');
            const planID = planActivityFeed.dataset.plan;

            fetch(`/plac/activity_feed/${planID}`)
            .then(response => response.json())
            .then(data => { planActivityFeed.innerHTML = data.html; })
            .catch(error => console.error('Error fetching activity feed:', error));
        }
    });
});
    

const btnAddPlanNeed = document.getElementById('btn-add-plan-need');
btnAddPlanNeed?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    const needOptionsList = document.getElementById('plan-need-options');
    const needID = needOptionsList.value;
    if (needID) {
        fetch(`/plac/add_need/${planID}/${needID}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                window.location = window.location.href;
                // let needs = document.getElementById('plan-needs');
                // needs.innerHTML += data.html;
            }
        });
    }
});

const btnNeedItem = document.querySelectorAll('.btn-need-item');
btnNeedItem?.forEach((el) => {
    el.addEventListener('click', (e) => {
        const needID = e.target.dataset.need;
        const planID = e.target.dataset.plan;
        const needTitle = e.target.dataset.title;
        // scroll to #find-referrals-module
        const findReferralsModule = document.getElementById('find-referrals-module');
        const searchReferralOrganizations = document.getElementById('search-referral-organizations');
        searchReferralOrganizations.value = needTitle;
        searchReferralOrganizations.focus();
        findReferralsModule.scrollIntoView({behavior: 'smooth'});
        const organizations = document.getElementById('referral-organizations-list');
        organizations.innerHTML = '<div class="d-flex justify-content-center align-items-center text-large h-100"><i class="fa-solid fa-spinner fa-spin"></i></div>';
        fetch(`/plac/org_search?search=${needTitle}&plan=${planID}`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                organizations.innerHTML = data.html;
                bindPlanReferralAdd();
            }
        });

    });
});


// Probably depreceated already - CL
const btnArchivePlan = document.getElementById('btn-archive-plan');
btnArchivePlan?.addEventListener('click', (e) => {
    const planID = e.target.dataset.plan;
    fetch(`/plac/archive/${planID}`, {
        method: 'POST',
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            window.location.href = data.redirect;
        }
    });
});

const copyLinkCheckbox = document.getElementById('copy-link-checkbox');
copyLinkCheckbox?.addEventListener('change', (e) => {
    const copyPlanLinkButton = document.getElementById('btn-copy-plan-link');
    copyPlanLinkButton.classList.toggle('disabled');
    if (copyPlanLinkButton.classList.contains('disabled')) {
        copyPlanLinkButton.setAttribute('disabled', 'disabled');
    } else {
        copyPlanLinkButton.removeAttribute('disabled');
    }
});

const copyPlanLinkButton = document.getElementById('btn-copy-plan-link');
copyPlanLinkButton?.addEventListener('click', (e) => {
    if (copyPlanLinkButton.classList.contains('disabled')) {
        return;
    } else {
        const modalEl = document.getElementById('copy-link-modal');
        const modalInstance = bootstrap.Modal.getInstance(modalEl);
        modalInstance.hide();
        document.querySelector('.modal-backdrop').remove();
        setTimeout(() => {
            document.body.style = '';
        }, 500);

        const dataLink = copyPlanLinkButton.getAttribute('data-link');
        navigator.clipboard.writeText(dataLink);
        document.execCommand('copy');

        const toast = document.querySelector('#toast-link-copied');
        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
        toastBootstrap.show();
    }
});


const checklistTitleInput = document.querySelectorAll('.checklist-title-input');
checklistTitleInput?.forEach((el) => {
    el.addEventListener('blur', (e) => {
        const titleValue = e.target.value;
        const checklistID = e.target.dataset.checklist;
        if (titleValue.length >= 3) {
            fetch(`/plac/update_checklist/${checklistID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({'Title': titleValue}),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let checklist = document.getElementById(`checklist-${checklistID}`);
                    let title = checklist.querySelector('.checklist-title');
                    title.innerHTML = titleValue;
                    title.classList.remove('hide');
                    checklist.querySelector('.checklist-title-input').classList.add('hide');
                }
            });
        }
    });
    el.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    });
});

const editPlanPage = document.querySelector('#edit-plan-view');
editPlanPage?.addEventListener('click', (e) => {
    if (e.target.classList.contains('checklist-title')) {
        const title = e.target;
        const titleBar = title.closest('.checklist-title-bar');
        const titleInput = titleBar.querySelector('input');
        titleBar.querySelector('.checklist-title').classList.add('hide');
        titleInput.classList.remove('hide');
        titleInput.focus();
        
    }

    // Delete a Need Section
    if (e.target.classList.contains('btn-delete-need')) {
        const needID = e.target.dataset.need;
        fetch(`/plac/delete_need/${needID}`, {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                const modalEl = document.getElementById(`delete-need-modal-${needID}`);
                console.log(needID);
                console.log(modalEl);
                hideModal(modalEl);
                let needSection = document.getElementById(`plan-need-${needID}`);
                needSection.remove();
            }
        });
    }

    // Add an item to a checklist
    if (e.target.classList.contains('btn-add-task')) {
        e.target.closest('.checklist').querySelector('.form-add-checklist-item').classList.add('show');
    }

    if (e.target.classList.contains('btn-cancel-checklist-item')) {
        e.target.closest('.checklist').querySelector('.form-add-checklist-item').classList.remove('show');
    }

    if (e.target.classList.contains('btn-create-task')) {
        const checklistID = e.target.dataset.checklist;
        form = e.target.closest('.form-add-checklist-item');
        const formData = {};
        const formElements = form.querySelectorAll('input, textarea');
        formElements.forEach((el) => {
            formData[el.name] = el.value;
        });

        if (form.querySelector('input[name="Title"]').value.length >= 3) {
            fetch(`/plac/create_task/${checklistID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    form.reset();
                    form.insertAdjacentHTML('beforebegin', data.html);
                    form.classList.remove('show');
                }
            });
        }
    }

    // Edit a task
    if (e.target.classList.contains('btn-edit-task')) {
        const taskID = e.target.dataset.task;
        const task = document.getElementById(`task-${taskID}`);
        const form = task.querySelector('.form-edit-task');
        // const textarea = form.querySelector('.mde-field');
        task.querySelector('.checklist-item-inner').style.display = 'none';
        form.style.display = 'block';
        window.dispatchEvent(new Event('resize')); // trigger resize event to fix MDE
    }
    if (e.target.classList.contains('btn-cancel-edit')) {
        const taskID = e.target.dataset.task;
        const task = document.getElementById(`task-${taskID}`);
        task.querySelector('.checklist-item-inner').style.display = 'flex';
        task.querySelector('.form-edit-task').style.display = 'none';
    }
    if (e.target.classList.contains('btn-save-task')) {
        const taskID = e.target.dataset.task;
        const task = document.getElementById(`task-${taskID}`);
        const form = e.target.closest('.form-edit-task');
        const formData = {};
        const formElements = form.querySelectorAll('input');
        formElements.forEach((el) => {
            formData[el.name] = el.value;
        });

     
        const taskDescription = document.getElementById(`form-edit-task-description-${taskID}`);
        formData['Description'] = taskDescription.value;
        formData['Complete'] = form.querySelector('input[name="Complete"]').checked;

        if (form.querySelector('input[name="Title"]').value.length >= 3) {
            fetch(`/plac/update_task/${taskID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {
                // replace html
                if (data.success) {
                    task.insertAdjacentHTML('beforebegin', data.html);
                    // TODO - Init MDE on new textarea
                    task.closest('.checklist').querySelector('.progress').style.width = `${data.completedPercentage}%`;
                    task.closest('.checklist').querySelector('.progress-label').innerHTML = `${data.completedPercentage}%`;
                    task.remove();
                }
            });
        }
    }
    if (e.target.classList.contains('btn-complete-task')) {
        const taskID = e.target.dataset.task;
        const value = e.target.checked;
        fetch(`/plac/complete_task/${taskID}?complete=${value}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                let task = document.getElementById(`task-${taskID}`);
                let form = task.querySelector('.form-edit-task');
                form.querySelector('input[name="Complete"]').checked = value;
                task.closest('.checklist').querySelector('.progress').style.width = `${data.completedPercentage}%`;
                task.closest('.checklist').querySelector('.progress-label').innerHTML = `${data.completedPercentage}%`;
            }
        });
    }

    if (e.target.classList.contains('btn-delete-task')) {
        const taskID = e.target.dataset.task;
        fetch(`/plac/delete_task/${taskID}`, {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                let task = document.getElementById(`task-${taskID}`);
                task.remove();
                const modalEl = document.getElementById(`delete-task-modal-${taskID}`);
                const modalInstance = bootstrap.Modal.getInstance(modalEl);
                modalInstance.hide();
                document.querySelector('.modal-backdrop').remove();
                setTimeout(() => {
                    document.body.style = '';
                }, 500);
            }
        });
    }

});

// addChecklistItemForm = document.querySelectorAll('.form-add-checklist-item');
// addChecklistItemForm?.forEach((el) => {
//     el.addEventListener('submit', (e) => {
//         e.preventDefault();
//     });
// });

const planNeedsChecklists = document.getElementById('checklists');
planNeedsChecklists?.addEventListener('click', (e) => {
    if (e.target.classList.contains('btn-remove-referral')) {
        const referralID = e.target.dataset.referral;
        const planID = e.target.dataset.plan;
        fetch(`/plac/remove_referral/${planID}/${referralID}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                let referral = document.getElementById(`referral-card-${referralID}`);
                referral.remove();
                // Close the modal
                const modalEl = document.getElementById(`remove-referral-modal-${referralID}`);
                const modalInstance = bootstrap.Modal.getInstance(modalEl);
                modalInstance.hide();
                document.querySelector('.modal-backdrop').remove();
            }
        });
    }

    if (e.target.classList.contains('btn-add-referral-note')) {
        const referralID = e.target.dataset.referral;
        const form = e.target.closest('.form-add-referral-note');
        const formData = {};
        formData['ReferralNote'] = form.querySelector('textarea').value;
        fetch(`/plac/update_referral/${referralID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                const modal = document.getElementById(`add-referral-note-modal-${referralID}`);
                hideModal(modal);
                const referralCard = document.getElementById(`referral-card-${referralID}`);
                referralCard.insertAdjacentHTML('beforebegin', data.html);
                referralCard.remove();
            }
        });
    }

    if (e.target.classList.contains('btn-update-referral-visibility')) {
        const referralID = e.target.dataset.referral;
        const invisible = parseInt(e.target.dataset.invisible);
        const formData = {};
        formData['PlanVisibility'] = invisible ? 'ConnectorOnly' : '';
        fetch(`/plac/update_referral/${referralID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                let modal;
                if (invisible) {
                    modal = document.getElementById(`mark-referral-invisible-modal-${referralID}`);
                } else {
                    modal = document.getElementById(`mark-referral-visible-modal-${referralID}`);
                }
                let referralsEl = document.getElementById('plan-referrals');
                let modalInstance = bootstrap.Modal.getInstance(modal);
                modalInstance.hide();
                document.querySelector('.modal-backdrop').remove();
                document.body.style = '';
                window.location = window.location.href;
                referralsEl.insertAdjacentHTML('beforebegin', data.html);
                referralsEl.remove();
            }
        });
    }

});


const findReferralsModule = document.getElementById('find-referrals-module');
findReferralsModule?.addEventListener('click', (e) => {

    if (e.target.id === 'btn-load-more-organizations') {
        const page = e.target.dataset.page;
        const search = document.getElementById('search-referral-organizations').value;
        fetch(`/plac/org_search?page=${page}&search=${search}`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                e.target.insertAdjacentHTML('afterend', data.html);
                e.target.remove();
            }
        });
    }

    if (e.target.classList.contains('btn-expand-referral')) {
        const referral = e.target.closest('.card-list-view');
        referral.classList.toggle('expanded');
    }    
    
});

const linkExpandRow = document.querySelectorAll('.link-expand-row');
linkExpandRow?.forEach((el) => {
    el.addEventListener('click', (e) => {
        e.stopPropagation();
        const expenseID = e.target.dataset.expense;
        const row = document.getElementById(`expense-details-row-${expenseID}`);
        row.classList.toggle('expanded');
        e.target.classList.toggle('expanded');
    });
});

//loads the stat tiles on connector dashboard
if (document.querySelector('.ConnectorDashboardPage')) {
    fetch('/connector-dashboard/stats')
    .then(response => response.json())
    .then(data => {
        document.getElementById('dashboard-client-count').innerText = data.clients;
        document.getElementById('dashboard-plan-count').innerText = data.plans;
        document.getElementById('dashboard-referral-count').innerText = data.referrals;
        document.getElementById('dashboard-connected-count').innerText = data.connections+"%";
    })
    .catch(error => console.error('Error fetching stats:', error));
}


    
    
if (document.getElementById('plan-activity-feed')) {
    const planActivityFeed = document.getElementById('plan-activity-feed');
    const planID = planActivityFeed.dataset.plan;

    fetch(`/plac/activity_feed/${planID}`)
    .then(response => response.json())
    .then(data => {
        planActivityFeed.innerHTML = data.html;
    })
    .catch(error => console.error('Error fetching activity feed:', error));
}